const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/5.png",
    text: "5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/buildersfloorashokvihar/6.png",
    text: "6",
  },
];

export default data;
