import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Builder Floor, Ashok Vihar",
  address: "Ashok Vihar, Delhi",
  client: "Bunty Sethi",
  area: "300 Sq. Yds",
  project: "Builder Floor",
  category: "Residential | Architecture | House",
  status: "Completed",
  backlink: "/projects/residential/",
};

export default class Projectbuildersfloorashokvihar extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Residential Projects | Builder Floor, Ashok Vihar"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View details and photographs from the beautifully designed and executed builder’s floor project located in Ashok Vihar, Delhi."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
